<template>
  <div>
    <a @click="$router.back()" href="#" class="
        text-dark-75
        font-weight-bolder
        text-hover-primary
        mb-1
        font-size-lg
        d-flex
        flex-row
        align-items-center
      ">
      <i class="mdi mdi-chevron-left"></i>
      Kembali
    </a>
    <validation-observer ref="formPaket">
      <div class="card card-custom card-stretch">
        <div class="card-header border-0 py-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label font-weight-bolder text-dark">
              Buat Paket Soal
            </span>
          </h3>
          <div class="card-toolbar">
            <button type="button" @click="submitForm" class="btn btn-success font-weight-bolder font-size-sm mr-2">
              Simpan
            </button>
            <span @click="$router.back()" class="btn btn-secondary font-weight-bolder font-size-sm">
              Batal</span>
          </div>
        </div>
        <div class="card-body pt-4">
          <b-form-group id="input-group-1" label="Nama Paket:" label-for="input-1">
            <validation-provider #default="{ errors }" name="Nama Vendor" rules="required">
              <div class="spinner spinner-primary spinner-left" v-if="isLoading">
                <input class="form-control mb-5" />
              </div>
              <b-form-input v-if="!isLoading" :state="errors.length > 0 ? false : null" id="input-1"
                v-model="currentForm.name" type="text" placeholder="Masukkan Nama Paket"></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
      </div>
    </validation-observer>
    <br />
    <template v-if="!isDetail">
      <div class="card card-custom card-stretch">
        <div class="card-header border-0 py-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label font-weight-bolder text-dark">
              Bank Soal
            </span>
          </h3>
        </div>
        <div class="card-body pt-4">
          <b-row class="justify-content-between">
            <b-col cols="auto">
              <b-form-group>
                <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="auto">
              <b-form-group label="Filter" label-for="filter-input" label-cols-sm="3" label-align-sm="right"
                label-size="sm" class="mb-0">
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filter" type="search"
                    placeholder="Type to Search"></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-table class="
              table
              table-head-custom
              table-vertical-center
              table-head-bg
              table-borderless
              card-table
            " :filter="filter" :items="currentBankSoalList.data" :fields="fields" :busy="isLoading"
            @filtered="onFiltered" show-empty responsive>
            <!--tbody-tr-class="card card-table-row card-custom card-stretch gutter-b"-->
            <template #empty>
              <h6 class="text-center">Tidak ada soal ditemukan</h6>
            </template>
            <template #emptyfiltered>
              <h6 class="text-center">Tidak ada soal ditemukan</h6>
            </template>
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(index)="row">
              {{ perPage * (currentPage - 1) + (row.index + 1) }}
            </template>
            <template #row-details="row">
              <div class="text-dark-75 font-weight-bolder mb-1 font-size-lg">
                Soal:
              </div>
              <div v-html="row.item.question"></div>
            </template>
            <template #cell(soal)="data">
              <div v-html="data.item.question" style="
                  max-width: 200px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                "></div>
            </template>
            <template #cell(kode_soal)="data">
              {{ data.item.quetion_code }}
            </template>
            <template #cell(tipe)="data">
              <span class="
                  label label-lg label-inline
                  font-weight-bold
                  py-4
                  label-light-info
                  mr-1
                ">
                {{ data.item.type }}
              </span>
            </template>
            <template #cell(bobot)="data">
              <!--          <b-badge class="mr-1" pill href="#" variant="info" v-for="topik in data.item.topik" :key="topik">-->
              <!--            {{ topik }}-->
              <!--          </b-badge>-->
              <span class="
                  label label-lg label-inline
                  font-weight-bold
                  py-4
                  label-light-info
                  mr-1
                ">
                {{ data.item.bobot }} pts
              </span>
            </template>
            <template #cell(topic)="data">
              <span class="
                  label label-lg label-inline
                  font-weight-bold
                  py-4
                  label-light-info
                  mr-1
                " v-for="topik in data.item.topic" :key="topik">
                {{ topik }}
              </span>
            </template>
            <template #cell(action)="data">
              <span @click="data.toggleDetails" class="custom-v-dropdown btn btn-light-info btn-icon mr-1">
                <!--                  {{ data.detailsShowing ?'Sembunyikan':'Lihat Deskripsi' }}-->
                <i :class="`ki ki-bold-arrow-${data.detailsShowing ? 'up' : 'down mt-1'
      } icon-sm`"></i>
              </span>
              <span @click="viewJawaban(data.item)" class="
                  btn btn-light-primary
                  font-weight-bolder font-size-sm
                  mr-1
                ">Daftar Jawaban</span>
              <span @click="chooseSoal(data.item)" :class="`btn ${isExist(data.item._id) ? 'btn-warning' : 'btn-info'
      } font-weight-bolder font-size-sm
                mr-1`">
                {{ isExist(data.item._id) ? "Soal Dipilih" : "Pilih Soal" }}
              </span>
            </template>
          </b-table>
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right"
            class="my-0"></b-pagination>
        </div>
      </div>
    </template>
    <template v-if="isDetail">
      <PaketDetail />
    </template>
    <b-modal v-model="modalJawaban" centered scrollable title="Pilihan Jawaban" hide-footer>
      <div class="jawaban">
        <div :class="`card card-custom card-stretch border ${jawaban.isCorrect
      ? 'bg-light-primary text-primary border-primary'
      : ''
      }`" v-for="(jawaban, i) in selectedSoal.answer" :key="`${selectedSoal.id}${i}`">
          <div class="
              card-body
              d-flex
              flex-row
              p-4
              justify-content-between
              align-items-center
            ">
            <div v-html="jawaban.answer"></div>
            <div v-if="jawaban.point && jawaban.point > 0">
              {{ jawaban.point }} pts
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import Swal from "sweetalert2";

import { ValidationObserver, ValidationProvider } from "vee-validate";

import { GET_BANK_SOAL } from "@/core/services/store/banksoal.module";
import {
  STORE_PAKET,
  UPDATE_PAKET,
  GET_ONE_PAKET,
} from "@/core/services/store/paket.module";
import PaketDetail from "@/view/pages/paket/PaketDetail.vue";

export default {
  name: "SiswaForm",
  components: {
    ValidationObserver,
    ValidationProvider,
    PaketDetail,
  },
  data() {
    return {
      currentForm: {},
      isLoading: false,
      currentPage: 1,
      totalRows: 0,
      selectedSoal: {},
      perPage: 10,
      selectedMapel: null,
      modalJawaban: false,
      modalBankSoal: false,
      fields: [
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "question_code",
          label: "Kode Soal",
          sortable: true,
        },
        {
          key: "type",
          label: "Tipe Soal",
          sortable: true,
        },
        {
          key: "topic",
          label: "Topik Soal",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          sortable: false,
        },
      ],
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      filter: '',
      editorOption: {
        modules: {},
        readOnly: true,
        theme: "bubble",
      },
      questions: [],
    };
  },
  mounted() {
    this.getData();

    if (this.idQuestion) {
      this.getDetail();
    }
  },
  watch: {
    filter: function () {
      this.getData();
    },
    perPage: function () {
      this.getData();
    },
    currentPage: function () {
      this.getData();
    }
  },
  methods: {
    isExist: function (item) {
      if (this.questions.indexOf(item) !== -1) {
        return true;
      }

      return false;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // this.totalRows = filteredItems.length;
      // console.log(filteredItems)
      // this.currentPage = 1;
    },
    submitForm() {
      if (!this.idQuestion) {
        this.currentForm.questions = this.questions;
      } else {
        this.currentForm.id = this.idQuestion;
      }

      this.$store
        .dispatch(
          this.idQuestion ? UPDATE_PAKET : STORE_PAKET,
          this.currentForm
        )
        .then((response) => {
          Swal.fire({
            title: response.message,
            text: `Berhasil Membuat Paket Soal`,
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.$router.push(`/paket-soal`);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Membuat Paket Soal`,
            variant: "danger",
            solid: true,
          });
        });
    },
    getData() {
      this.isLoading = true;
      const param = new URLSearchParams();
      param.append("limit", this.perPage);
      param.append("page", this.currentPage);
      if (this.filter) {
        param.append("where['topic']", this.filter);
      }
      this.$store
        .dispatch(GET_BANK_SOAL, param.toString())
        .then(() => {
          this.isLoading = false;
          this.totalRows = this.currentBankSoalList.total;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Soal`,
            variant: "danger",
            solid: true,
          });
        });
    },
    onReset() { },
    viewJawaban(item) {
      this.selectedSoal = item;
      this.modalJawaban = true;
    },
    chooseSoal(item) {
      if (this.questions.includes(item._id)) {
        this.questions.splice(this.questions.indexOf(item._id), 1);
      } else {
        this.questions.push(item._id);
      }
    },
    getDetail() {
      this.isLoading = true;
      this.$store
        .dispatch(GET_ONE_PAKET, this.idQuestion)
        .then((response) => {
          this.isLoading = false;

          this.currentForm.name = response.data.name;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Soal`,
            variant: "danger",
            solid: true,
          });
        });
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.ujian.errors,
    }),
    ...mapGetters(["currentBankSoalList"]),
    isDetail() {
      if (this.$route.params.idQuestion !== undefined) {
        return true;
      }
      return false;
    },
    idQuestion() {
      return this.$route.params.idQuestion;
    },
  },
};
</script>
